.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2002;
  display: none;
  background-color: rgba(0, 0, 0, .1);

  &--visible {
    display: block;
  }
}