.wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--background-green);
  color: var(--content-white);
  border-radius: 50%;
}

.select {
  padding: 5px 0;
  font-weight: 600;
  color: var(--content-primary);
  border: 1px solid var(--border-success);
  border-radius: var(--radius-sm);
}