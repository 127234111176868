.badge {
  display: inline-block;
  padding: 8px 10px;
  border-radius: var(--radius-sm);
  background-color: var(--status-noactive-light);
  color: var(--status-noactive);

  &--active {
    background-color: var(--status-active-light);
    color: var(--status-active);
  }
}