.wrapper {
  padding-top: 20px;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.buttons {
  display: flex;
  column-gap: 15px;
}