.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  background-color: var(--background-primary);
  border: 1px dashed var(--border-success); // #6d8297
  border-radius: var(--radius-sm);
  transition: all .3s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &--state {
    &-default {
      border-color: var(--border-primary);
    }

    &-success {
      border-color: var(--border-success);
    }

    &-error {
      border-color: var(--border-danger);
    }
  }

  &--uploaded {
    border: 1px solid var(--border-primary);
  }

  input {
    display: none;
  }
}


.name {
  max-width: 75%;
  width: 100%;
  margin-left: 10px;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  color: var(--content-danger);
}
