.wrapper {
  display: flex;
  height: 100vh;
}

.content {
  flex-grow: 1;
  height: 100vh;
  padding-bottom: 50px;
  overflow-y: auto;
}

.container {
  max-width: 1540px;
  width: 100%;
  padding: 20px 20px;
  margin-left: auto;
  margin-right: auto;
}