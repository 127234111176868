.wrapper {
  position: relative;
  max-width: 395px;
  width: 100%;
  border-radius: var(--radius-sm);
  overflow: hidden;
}

.input {
  width: 100%;
  padding: 14px 90px 13px 20px;
  background-color: var(--background-secondary);
  color: var(--content-secondary);
  border: none;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77px;
  background-color: var(--background-green);
  color: var(--content-white);
  cursor: pointer;
}