.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: var(--radius-md);

  &--transparent {
    background-color: transparent;
  }

  &--white {
    margin-bottom: 20px;
    background-color: var(--background-primary);
  }
}