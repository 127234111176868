.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--content-primary);
}

.description {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  color: var(--content-tertiary);
}