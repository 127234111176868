html,
body {
  margin: 0;
  padding: 0;
}

html {
  vertical-align: baseline;
}

body {
  font-weight: normal;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-secondary);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  margin: 0;
  padding: 0;
  background: #fff;
  border: 0;
  outline: none;
}

form {
  width: 100%;
}

input,
textarea,
select {
  outline: none;
}