.wrapper {
  display: inline-flex;
  margin-bottom: 20px;
  background-color: rgba(17,16,55,0.05);
  border-radius: 30px;
}

.item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px 50px;
  font-weight: 600;
  color: var(--content-primary);
  border-radius: 30px;
  cursor: pointer;
  transition: all .2s ease;

  &--active {
    background-color: var(--background-green);
    color: var(--content-white)
  }
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--background-danger);
  color: var(--content-white);
}