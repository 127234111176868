.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2003;
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
}

.circle {
  fill: transparent;
  stroke: var(--border-success);
  stroke-width: 7;
  stroke-linecap: round;
  filter: url(#shadow);
  transform-origin: center;
  animation-name: animation;
  animation-duration: 0.58s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}