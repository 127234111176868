.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-secondary);
}

.content {
  padding: 30px;
  width: 450px;
  background-color: var(--background-primary);
  border-radius: var(--radius-md);
}